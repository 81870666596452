.header {
	width: 100%;

	background-color: #FFF0;
	/* padding-bottom: 1vh; */
	/* padding-top: 1vh; */
	/* margin-bottom: 1vh; */
}

.header td {
	font-size: 3vw;
	text-align: right;
}

.header .user.name {
	text-align: center;
  vertical-align: middle;

  font-size: 2.75vw;
	font-weight: bolder;
	word-break: keep-all;
}

.header .label {
	text-align: right;
  vertical-align: middle;

	font-size: 3vw;
	font-weight: lighter;
	padding: 0 2vw 0 0;
}

.header .value {
	text-align: left;
  vertical-align: middle;

  font-size: 3vw;
	font-weight: bolder;
	border-left: thin solid darkgray;
	padding-left: 1vw;
}


.header .progress.bar {
  border-left: thin solid black;
  border-right: thin solid black;
  margin-top: -1vh;
  width: 40vw;
}

.header .progress.bar span {
  display: inline-block;
  position: absolute;
  top: 50%;
  font-size: 2.6vw;
}

.header .progress-label {
  left: 0;
  padding-left: 1vh;
}

.header .progress-value {
  left: 50%;
  padding: 0;
  color: yellow;
}

.header .progress-next {
  right: 0;
  padding-right: 1vw;
  color: white;
}


.header progress {
	background-color: darkgray;
	border-top-right-radius: 2rem;
	border-bottom-right-radius: 2rem;
	height: 4vh;
	width: 100%;
	color: rgba(238,183,53,1);
}

.header progress::-webkit-progress-bar {
	border-top-right-radius: 2rem;
	border-bottom-right-radius: 2rem;
	background-size: 35px 20px, 100% 100%, 100% 100%;
	color: rgba(238,183,53,1);
}

.header progress::-moz-progress-bar {
	border-top-right-radius: 2rem;
	border-bottom-right-radius: 2rem;
	background-size: 35px 20px, 100% 100%, 100% 100%;
	color: rgba(238,183,53,1);
}

.header progress::-webkit-progress-value {
	border-top-right-radius: 2rem;
	border-bottom-right-radius: 2rem;
	background-color: rgba(238,183,53,1);
	color: rgba(238,183,53,1);
}

.header progress::-moz-progress-value {
	border-top-right-radius: 2rem;
	border-bottom-right-radius: 2rem;
	background-color: rgba(238,183,53,1);
	color: rgba(238,183,53,1);
}
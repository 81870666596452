section.login {}

section.login label {
  display: inline-block;
  position: relative;
  left: 0;
  width: 15vw;
  text-align: right;
  padding-right: 10px;
}

section.login a {
  font-size: 2rem;
  color: var(--main-color);
  padding: 1rem 0 1rem 0;
  margin: 1rem 0 1rem 0;
}
.mini.news.title {
  font-size: 3vw;
  font-weight: bolder;
}


.mini.news.contents {
  font-size: 2vw;
  /* font-weight: bolder; */
  padding-left: 1vw;
}


.mini.news.decor {
  position: absolute;
  right: -2vw;
  top: -2vh;
}
.machine.selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: #fff;
}

.machine.selector img {
  width: 20vw;
  border: thick ridge #FFF3;
}

.machine.selector img.highlight {
  border: thick ridge white;
}